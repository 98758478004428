<template>
  <span class="check_bookmark">
    <label>
      <input v-model="selected" type="checkbox" true-value="Y" false-value="N" @change="handleUpdateBookmark">
      <i class="icon_font_bookmark_02" />
      <span class="icon_font_bookmark_03" />
    </label>
  </span>
</template>

<script setup lang="ts">
import useContentInterest from '~/modules/content/composables/interest'
import useContentNavigator from '~/modules/content/composables/navigate'
import { useMemberNavigator } from '~/modules/member/common/composables/navigator'

interface IData {
  id: number
  bookmarkYn: string
}

const { id, bookmarkYn } = defineProps<IData>()

const emit = defineEmits<{(e: 'update', item: IData): void}>()

const route = useRoute()
const config = useRuntimeConfig()
const { navigateToInterestBookmark } = useContentNavigator()
const { checkedLogin, bookmark } = useContentInterest()
const { navigateToLogin } = useMemberNavigator()

const confirm: any = inject('confirm')
const snackbar: any = inject('snackbar')

const selected = ref('N')
onMounted(() => {
  selected.value = bookmarkYn ?? 'N'
})

const handleUpdateBookmark = () => {
  checkedLogin(
    async () => {
      await bookmark(id, selected.value)

      if (selected.value === 'Y') {
        snackbar.info('관심 목록에 추가되었습니다', '관심 목록 보기', () => {
          navigateToInterestBookmark()
        })
      }

      emit('update', { id, bookmarkYn: selected.value })
    },
    () => {
      selected.value = selected.value === 'Y' ? 'N' : 'Y'

      confirm.open({
        title: '로그인이 필요합니다<br />로그인 페이지로 이동할까요?',
        confirm: () => {
          navigateToLogin({ query: { redirectUrl: `${config.public.SHOP_HOST}${route.fullPath}` } })
        }
      })
    }
  )
}

</script>

<style scoped>
.check_bookmark label { width: 24px; height: 24px; display: flex; align-items: center; justify-content: center; }
.check_bookmark input[type="checkbox"] { width: 0px; height: 0px; overflow: hidden; border: 0; opacity: 0; -webkit-appearance: none; -moz-appearance: none; appearance: none; position: absolute; }
.check_bookmark .icon_font_bookmark_02 { display: inline-block; }
.check_bookmark .icon_font_bookmark_03 { display: none; }
.check_bookmark input[type="checkbox"]:checked+.icon_font_bookmark_02 { display: none; }
.check_bookmark input[type="checkbox"]:checked+.icon_font_bookmark_02+.icon_font_bookmark_03 { display: inline-block; }
.check_bookmark.white .icon_font_bookmark_02:before { color: var(--color-background-default); }
.check_bookmark.white .icon_font_bookmark_03:before { color: var(--color-background-default); }

</style>
